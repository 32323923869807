<template>
  <div class="overlay" @click.self="closeModal">
    <div class="modal-content">


      <div v-if="true">
        <h2>Identifier</h2>
        <p>
          Provide an identifier that would allow the staff to recognize you as the author of this request. It could be a nickname, or something you've done, however make sure it's unique to you. Some examples are: the Viking Guy who stormed Capitol building, Alan Turing.
          The staff may be hesitant to ressurect if they are not completely as to who authored the request. Necromantic practices without the concent of the subject may result in serious lawsuits.
        </p>
        <input type="text" v-model="identifier">
      </div>

      <div v-if="false">
        <h2>Date of resurrection</h2>
        <p>Althought the existence of technology is not guaranteed by any particular date, indicate the earliest time when you would want to be resurrected.
        </p>

        <h2>Accomodations</h2>
        <p>
          You may find your first moments after reawakening troubling. The reaction could range from confusion to psychosis. What kind of accomodations would help you endure the process?
        </p>
        <textarea v-model="accomodations"></textarea>
      </div>

      <div v-if="false">

        <h2>Consent Agreement</h2>
        <p></p>

        <h2>Make your request publically visible</h2>
        <p>This is absolutely optional and will not have any affect on your request status.</p>
        <input type="checkbox" v-model="visible">


        <button @click="submitRequest">Submit</button>
      </div>

    </div>
  </div>



</template>

<script>
  export default {
    data() {
      return {
        identifier: null,
        date: null,
        accomodations: null,
        visible: false
      }
    },
    methods: {
      submitRequest() {
        this.$router.push({
          name: 'Necromancy'
        })
      },
      closeModal() {
        this.$router.push({
          name: 'Necromancy'
        })
      }
    }
  }
</script>

<style scoped>
  .overlay {
    z-index: 1;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #00000055;
  }

  .modal-content {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
  }
</style>