import {createStore} from "vuex";
import necromancy from '@/store/modules/necromancy.js'

var store =  createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    necromancy
  },
});

store.dispatch("necromancy/fetchPeople");

export default store;
