<template>
  <div>
    <h1>Requesting ressurection services</h1>

    <p v-for="p in people" :key="p.name">{{p.name}}</p>
  <button @click="summonModal">FORM SUMMON</button>
  <button @click="hello">Firebase</button>



    <div v-if="showModal" class="modal-route" >
        <div class="modal-route">
          <router-view></router-view>
        </div>
    </div>

  </div>

  <!--  <p v-for="p in necro" :key="p.name">{{ p.name }}</p>-->



</template>


<script>
  //  import { mapStore } from 'vuex'


  export default {
    data() {
      return {
        necro: null
      }
    },
    computed: {
      people() {
        return this.$store.state.necromancy.people
      },
      showModal() {
        return this.$route.name == 'NecromancyModal'
      },
      su() {
        return this.$store.state.necromancy.su
      }
    },
    methods: {
      summonModal() {
        this.$router.push({
          name: 'NecromancyModal'
        })
      },
      hello() {}
    }
  }
</script>


<style scoped>
  .modal-route {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
  }

</style>