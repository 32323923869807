import db from '@/firebaseInit'

const state = {
    people: null,
    modalState: 1,
    modalStateMax: 3
}

const mutations = {
  SET_PEOPLE(state, value) {
    state.people = value
  },
  SET_MODAL_STATE(state, value) {
    state.modalState = value;
  }
}

const actions = {
  
  
  fetchPeople({commit}) {
   db.collection('necromancy')
     .get()
     .then((queryResult) => {
        const people = queryResult.docs.map((doc => doc.data()))
        commit('SET_PEOPLE', people)
    })
     .catch(() => {console.log('error fetching data')})
  },
  inrcementModalState({commit, state}) {
    if (state.modalState < state.modalStateMax) {
      commit('SET_MODAL_STATE', state.modalState + 1)
    }
    
  },
  decrementModalState({commit, state}) {
    if (state.modalState > 0) {
      commit('SET_MODAL_STATE', state.modalState - 1)
    }
    
  }

  
}

const getters =  {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
