import { createRouter, createWebHistory } from 'vue-router'
import About from '@/views/About.vue'
import Home from '@/views/Home.vue'
import Necromancy from '@/views/Necromancy.vue'
import NecromancyModal from '@/views/NecromancyModal.vue'
import Inventory from '@/views/Inventory.vue'
import Sound from '@/views/Sound.vue'

const routes = [
  {
    path: '/about',
    name: 'About',
    component: About
  }, 
  {
      path: '/',
      name: 'Home',
      component: Home
  },
  {
      path: '/inventory',
      name: 'Inventory',
      component: Inventory
  },
  {
      path: '/sound',
      name: 'Sound',
      component: Sound
  },  
  {
      path: '/necromancy',
      name: 'Necromancy',
      component: Necromancy,
      children: [
        {
          path: 'request',
          name: 'NecromancyModal',
          component: NecromancyModal
        }
      ]
    
      
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
